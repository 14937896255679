interface Config {
	baseDomain: string
	clientUrl: string
	forgotPasswordUrl: string
	resetPasswordUrl: string
	loginUrl: string
	storeTokenUrl: string,
	signUpUrl: string,
	sendOtpUrl: string, 
	validateOtpUrl: string,
	currentUserUrl: string
	userCreateEventPublishUrl: string
}

const baseDomain = process.env.REACT_APP_IK_PROXY_DOMAIN  
if(!baseDomain){
	console.log(`REACT_APP_IK_PROXY_DOMAIN is missing`)
	throw Error('REACT_APP_IK_PROXY_DOMAIN is missing')
}

const config: Config = {
	baseDomain: baseDomain,
	clientUrl: baseDomain ? `${baseDomain}/user/v1/clients` : "",
	forgotPasswordUrl: baseDomain ? `${baseDomain}/user/v1/users/forgot-password` : "",
	resetPasswordUrl: baseDomain ? `${baseDomain}/user/v1/users/reset-password` : "",
	loginUrl: baseDomain ? `${baseDomain}/user/v1/users/login` : "",
	storeTokenUrl: baseDomain ? `${baseDomain}/user/v1/users/auth-tokens` : "",
	signUpUrl: baseDomain ? `${baseDomain}/user/v1/users/onboard` : "",
	sendOtpUrl: baseDomain ? `${baseDomain}/user/v1/users/mfa/send` : "",
	validateOtpUrl: baseDomain ? `${baseDomain}/user/v1/users/mfa/validate` : "",
	currentUserUrl: baseDomain ? `${baseDomain}/user/v1/users/current` : "",
	userCreateEventPublishUrl: baseDomain ? `${baseDomain}/user/v1/user-events/publish` : "",


}

export default config
