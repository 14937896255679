import { lazy } from 'react';

interface RetryOptions {
  retries: number;
  delay: number; // delay in milliseconds
}

async function retry(fn: () => Promise<any>, retriesLeft: number, interval: number): Promise<any> {
  try {
    return await fn(); // Attempt to execute the function
  } catch (error) {
    if (retriesLeft === 0) {
      throw error; // All retries exhausted, throw the error
    }
    await new Promise(resolve => setTimeout(resolve, interval)); // Wait for the specified delay
    console.log('retrying');
    return retry(fn, retriesLeft - 1, interval); // Recursively retry
  }
}

export function lazyWithRetry(dynamicImport: () => Promise<any>, options: RetryOptions = { retries: 3, delay: 1000 }) {
  return lazy(() => retry(dynamicImport, options.retries, options.delay));
}
