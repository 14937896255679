import { updateCallbackUrl } from "./auth.util"

export function extractCallbackUrl(url: string): string {
	const param = "callbackUrl="
	const startIndex = url.indexOf(param)

	if (startIndex === -1) {
		return ""
	}

	const adjustedStartIndex = startIndex + param.length

	// Extract everything from the start of 'callbackUrl' to the end of the URL
	return decodeURIComponent(url.substring(adjustedStartIndex))
}

export function extractUrlParams(location: string) {
	const urlParams = new URLSearchParams(location)

	// Extract parameters
	let resetToken = urlParams.get("resetToken") ?? null
	let redirectUri = urlParams.get("redirectUri") ?? ""
    let appCode = (urlParams.get("appCode") ?? "").trim();
	let mode = urlParams.get("mode") ?? "login"
	let platform = urlParams.get("platform") ?? ""
	let channel = urlParams.get("channel") ?? ""

	// Now, extract the callbackUrl with all following query parameters
	let callbackUrl = extractCallbackUrl(location)
	// Update the callbackUrl using updateCallbackUrl function in case onboarding
	if (callbackUrl) {
		callbackUrl = updateCallbackUrl(callbackUrl)
	}

	return {
		resetToken,
		redirectUri,
		callbackUrl,
		appCode,
		mode,
		platform,
		channel
	}
}