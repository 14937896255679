import React, { Suspense } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { createRoot } from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { setupAmplify } from "./utils/onboarding"
import AppRoutes from "./routes/AppRoutes"

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		"form-header": true
	}
}

const App = ({ error }) => {
	return (
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				<AppRoutes error={error} />
			</Suspense>
		</Router>
	)
}

const Root = () => {
	const [error, setError] = React.useState(null)
	const [isSetupComplete, setIsSetupComplete] = React.useState(false) // State to track setup completion

	React.useEffect(() => {
		const initializeApp = async () => {
			try {
				supportsCookies()
				await setupAmplify()
				setIsSetupComplete(true)
			} catch (error: any) {
				setError(error.message)
				setIsSetupComplete(true)
			}
		}
		initializeApp()
	}, [])

	if (!isSetupComplete) {
		return <div>Loading...</div>
	}
	return (
		<React.StrictMode>
			<App error={error} />
		</React.StrictMode>
	)
}

const rootElement = document.getElementById("root")
if (rootElement) {
	const root = createRoot(rootElement)
	root.render(<Root />)
}

reportWebVitals()

function supportsCookies() {
	try {
		// Create cookie
		document.cookie = "cookietest=1; path=/"

		// Check if cookie exists
		let ret = document.cookie.indexOf("cookietest=") !== -1

		// Delete cookie
		document.cookie = "cookietest=1; path=/; expires=Thu, 01-Jan-1970 00:00:01 GMT"
		console.log(`cookie is ${ret ? "enabled" : "disabled"}`)
		return ret
	} catch (e) {
		return false
	}
}
