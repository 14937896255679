import { Amplify } from "aws-amplify"
import awsConfig from "../aws-exports.js"
import axios from "axios"
import config from "../config"
import { AppClientCodes, isWebApp } from "./auth.util"
import { extractUrlParams } from "./index"

async function getClientId(clientName: string) {
    // Remove leading and trailing spaces from clientName
    const sanitizedClientName = clientName.trim();

    try {
        const response = await axios.get(`${config.clientUrl}/${sanitizedClientName}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.data.appClientId) {
            return response.data.appClientId;
        } else {
            // If no appClientId is found in the response but the request was successful
            throw new Error(`App code not found for '${sanitizedClientName}'.`);
        }
    } catch (error: any) {
        // Handle network errors that don't have a response (e.g., network down, timeout)
        if (!error.response) {
            throw new Error('Network error, please try again later.');
        } else {
            // Handle specific status codes
            switch (error.response.status) {
                case 404:
                    throw new Error(`App code not found for '${sanitizedClientName}', Please contact support.`);
                case 500:
                    throw new Error('Server error, please try again later.');
                default:
                    // Generic error for other cases
                    throw new Error('An unexpected error occurred, please try again.');
            }
        }
    }
}

export async function setupAmplify() {
	try {
		const location = window.location.search

		const { appCode, callbackUrl } = extractUrlParams(location)

		if (!appCode) {
			throw new Error("App code is missing")
		}

		if (!callbackUrl && isWebApp(appCode as AppClientCodes)) {
			throw new Error("Callback URL is missing for a web application.")
		}

		const appClientId = await getClientId(appCode)

		awsConfig.aws_user_pools_web_client_id = appClientId
		if (awsConfig.aws_user_pools_id && awsConfig.aws_user_pools_id.length >= 3) {
			console.log(awsConfig.aws_user_pools_id.slice(-3))
		} else {
			console.log("The string has less than 3 characters or is not defined")
		}

		Amplify.configure(awsConfig)
	} catch (error: any) {
		throw error
	}
}
